export const getBriefId = (hash: string) => {
  const indx = hash.indexOf("/");
  return ~indx ? hash.slice(1, indx) : hash.slice(1);
};

export const getHrefParams = (hash: string) => {
  const indx = hash.indexOf("?");
  return ~indx ? hash.slice(indx) : "";
};

export const extractFileName = (url: string) => url.replace(/^.*[\\\/]/, "");
