import { AnyAction } from "redux";
import * as Brief from "../constants/brief";

// ------------------------------------
// Interface
// ------------------------------------
export interface IBriefState {
  isFetching: boolean;
  organisation: string;
  date: string;
  start: string;
  end: string;
  requirement: string;
  specialism: string;
  level: string;
  approaches_made: number | null;
  positive_responses: number | null;
  skills: string[];
  sectors: string[];
  tools: string[];
  customers: string[];
  description: string;
  description_url?: string;
  last_update: string;
  talents?: Brief.ITalents[];
  id: number | null;
  message: string;
  spotlight_talents?: Brief.ITalents[];
  clients?: string[];
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IBriefState = {
  isFetching: false,
  approaches_made: null,
  organisation: "",
  date: "",
  end: "",
  skills: [],
  sectors: [],
  tools: [],
  customers: [],
  description: "",
  id: null,
  last_update: "",
  message: "",
  requirement: "",
  specialism: "",
  level: "",
  positive_responses: null,
  start: "",
  talents: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IBriefState => {
  switch (action.type) {
    case Brief.GET_BRIEF:
      return {
        ...state,
        isFetching: true,
      };
    case Brief.GET_BRIEF_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case Brief.GET_BRIEF_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case Brief.RESET_BRIEF:
      return initialState;
    default:
      return state;
  }
};
