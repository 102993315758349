import cn from "classnames";
import React from "react";

export const Status = ({ data }: { data: string }) => {
  const getStatusOptions = (status: string) => {
    let colorClass: string | undefined = undefined;
    let placeholder = status?.split("_").join(" ") || "";

    switch (status) {
      case "CLEARANCE_REQUESTED":
        colorClass = "requested";
        break;
      case "CLEARANCE_APPROVED":
        colorClass = "clearance-approved";
        break;
      case "CLEARANCE_DECLINED":
      case "REMOVED":
        colorClass = "declined";
        break;
      case "BOOKED":
        colorClass = "accepted";
        break;
      default:
        colorClass = "pending";
        break;
    }
    return {
      colorClass,
      placeholder,
    };
  };

  const { colorClass, placeholder } = getStatusOptions(data);

  return (
    <>
      <div className={cn("matching-status", colorClass)}>
        {placeholder === "BOOKED" ? "ALLOCATED" : placeholder}
      </div>
    </>
  );
};
