import React from "react";

export default function CardSkills({
  skills,
  sectors,
  tools,
}: {
  skills: string;
  sectors: string;
  tools: string;
}) {
  const renderItems = (title: string, skills: string[]) => (
    <div className="textWrapper">
      <div className="skill-list">
        <b>{title}: </b>
        {skills.length > 0 ? (
          <ul>
            {skills.map((skill: string, index: number) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        ) : (
          <span>—</span>
        )}
      </div>
    </div>
  );

  return (
    <>
      {renderItems("Skills", !!skills.length ? skills.split(", ") : [])}
      {renderItems("Sectors", !!sectors.length ? sectors.split(", ") : [])}
      {renderItems("Tools", !!tools.length ? tools.split(", ") : [])}
    </>
  );
}
