import React from "react";
import { useSelector } from "react-redux";
import Talents from "../Talents/Talents";
import LogoIcon from "~/assets/genie-logo.svg";
import { IState } from "~/store/reducers/index";
import "./TalentSection.scss";
import cn from "classnames";

const TalentSection = () => {
  const { talents, message, last_update, spotlight_talents } = useSelector(
    (state: IState) => state.brief
  );
  const canShowSpotlightSection =
    !!spotlight_talents?.length && !!talents?.length;
  const isNoTalentAccepted = !talents?.length;
  let briefMessage;

  if (message || isNoTalentAccepted) {
    briefMessage =
      message ||
      "If there are no talent available, your search might have been too specific, or the talent have been manually removed as they are no longer in consideration for this brief";
  }

  return (
    <div
      className={cn("wrp-talent-section", {
        ["frame"]: canShowSpotlightSection,
      })}
    >
      {!!talents?.length && (
        <Talents talents={talents} last_update={last_update} />
      )}

      {briefMessage && (
        <div className="talents-container">
          <h1
            className={cn({
              ["interested-talents"]: isNoTalentAccepted && !message,
            })}
          >
            {briefMessage}
          </h1>
        </div>
      )}

      {!message && (
        <div className="wrp-candidates-rates">
          <div className="candidates-rates">
            <p className="logo">
              <img alt="Genie" src={LogoIcon} />
            </p>
            <p className="description">
              *Please be aware that the webpage you are currently viewing
              contains sensitive employee information and must not be shared
              with anyone outside of the company. This information is strictly
              confidential and should be treated as such to ensure the privacy
              and security of our employees.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentSection;
