import { AxiosResponse } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const GET_BRIEF = "GET_BRIEF";
export const GET_BRIEF_SUCCESS = "GET_BRIEF_SUCCESS";
export const GET_BRIEF_ERROR = "GET_BRIEF_ERROR";
export const RESET_BRIEF = "RESET_BRIEF";

// ------------------------------------
// Interfaces
// ------------------------------------

export type ITalents = {
  image_url: string;
  profile_image_url: string;
  name: string;
  talent_feedback: string;
  description: string;
  portfolio_url: string;
  specialism: string;
  status: string;
  subhead: string;
  talent_country: string;
  timezone: {
    long: string;
    short: string;
    zone: string;
  };
  cv_url: string;
  skills: string[];
  sectors: string[];
  tools: string[];
  tro: string[];
  discipline_name: string;
  level_name: string;
  organisation_name: string;
  talent_phone: string;
  talent_email: string;
  talent_type: TalentType;
};

export enum TalentType {
  /* Permanent talents */
  INDIVIDUAL = "INDIVIDUAL",
  TEAM = "TEAM",
  /* Freelance talents */
  FREELANCER = "FREELANCER",
  FREELANCE_TEAM = "FREELANCE_TEAM",
}

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  getBriefRequest: () => ({
    type: GET_BRIEF,
  }),
  getBriefSuccess: (res: AxiosResponse) => ({
    type: GET_BRIEF_SUCCESS,
    payload: res,
  }),
  getBriefFailure: () => ({
    type: GET_BRIEF_ERROR,
  }),
  resetBrief: () => ({
    type: RESET_BRIEF,
  }),
};
