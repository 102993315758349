import cn from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import "./BriefInfo.scss";
import { extractFileName } from "~/utils";

export default function BriefInfo() {
  const [infoExpanded, setInfoExpanded] = useState(false);
  const brief = useSelector((state: IState) => state.brief);

  function expandInfo() {
    setInfoExpanded(!infoExpanded);
  }

  return (
    <div className="BriefInfo">
      <h1>Here are your results!</h1>
      <div className="BriefInfo__stats">
        <div>
          <h2>{brief.positive_responses}</h2>
          <p>Matched</p>
        </div>
      </div>
      <div className={cn("BriefInfo__info", { expanded: infoExpanded })}>
        <div>
          <strong>Organisation</strong>
          {!!brief.organisation && <span>{brief.organisation}</span>}
        </div>
        <div>
          <strong>Date Brief set</strong>
          <span>{brief.date}</span>
        </div>
        <div>
          <strong>Brief client</strong>
          <span>{!!brief?.clients?.length && brief.clients.join(", ")}</span>
        </div>
        <div>
          <strong>Specialism</strong>
          {!!brief.specialism && <span>{brief.specialism}</span>}
        </div>
        <div>
          <strong>Level</strong>
          {!!brief.level && <span>{brief.level}</span>}
        </div>
        <div>
          <strong>Start date</strong>
          <span>{brief.start}</span>
        </div>
        <div>
          <strong>End date</strong>
          <span>{brief.end}</span>
        </div>
        <div>
          <strong>Brief summary</strong>
          <span className="multiline">{brief.description}</span>
          {brief.description_url && (
            <span className="multiline">
              <a href={brief.description_url} target="_blank">
                {extractFileName(brief.description_url)}
              </a>
            </span>
          )}
        </div>
        {!!brief?.skills?.length && (
          <div>
            <strong>Skills</strong>
            <span className="multiline">{brief.skills?.join(", ")}</span>
          </div>
        )}
        {!!brief?.sectors?.length && (
          <div>
            <strong>Sectors</strong>
            <span className="multiline">{brief.sectors?.join(", ")}</span>
          </div>
        )}
        {!!brief?.tools?.length && (
          <div>
            <strong>Tools / Applications</strong>
            <span className="multiline">{brief.tools?.join(", ")}</span>
          </div>
        )}
        <button type="button" onClick={expandInfo}>
          {infoExpanded ? "Hide" : "View"} Brief info{" "}
          <i className={cn("icon-arrow-right", { iconHide: infoExpanded })}></i>
        </button>
      </div>
    </div>
  );
}
