import React, { useState } from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import cn from "classnames";
import { ITalents, TalentType } from "~/store/constants/brief";
import { joinArray } from "../helper";
import { Status } from "./Status";
import "./Talents.scss";
import CardSkills from "./CardSkills";

const CREATIVE_TEAM = "Creative Team";
const NO_REASON_GIVEN = "No Reason Given";

const Talent = ({
  talent,
  mode,
}: {
  talent: ITalents;
  mode: "normal" | "spotlight";
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const header = (
    <a href={talent.portfolio_url} target="_blank" className="wrp-img">
      <img alt="Card" src={talent.image_url} />
    </a>
  );

  const isTeam = [TalentType.TEAM, TalentType.FREELANCE_TEAM].includes(
    talent.talent_type
  );
  const feedbackTitle = isTeam ? "why we're right" : "why i'm right";
  const isFreelancer = talent.talent_type === TalentType.FREELANCER;

  const tabs = [
    {
      id: 0,
      title: "More info",
      description: (
        <>
          <div className="textWrapper">
            <p>
              <b>Bio:</b> {talent.description || "—"}
            </p>
          </div>
          <CardSkills
            skills={joinArray(talent.skills, "")}
            sectors={joinArray(talent.sectors, "")}
            tools={joinArray(talent.tools, "")}
          />
          <div className="textWrapper">
            <p>
              <b>Country of residence:</b> {talent.talent_country || "—"}
            </p>
          </div>
          <div className="textWrapper">
            <p>
              <b>Time zone:</b> {talent?.timezone?.short || "—"}
            </p>
          </div>
          {!isTeam && (
            <>
              {talent?.talent_phone && (
                <div className="textWrapper">
                  <p>
                    <b>Contact number:</b> {talent?.talent_phone}
                  </p>
                </div>
              )}
              {talent?.talent_email && (
                <div className="textWrapper">
                  <p>
                    <b>Contact email:</b> {talent?.talent_email}
                  </p>
                </div>
              )}
            </>
          )}
        </>
      ),
    },
    ...(talent.talent_feedback && talent.talent_feedback !== NO_REASON_GIVEN
      ? [
          {
            id: 1,
            title: feedbackTitle,
            description: <p>{talent.talent_feedback}</p>,
          },
        ]
      : []),
    ...(talent.cv_url
      ? [
          {
            id: 2,
            title: "Portfolio / CV",
            description: (
              <>
                <a href={talent.portfolio_url} target="_blank">
                  <span className="pi pi-external-link" /> View portfolio
                </a>
                <a href={talent.cv_url} target="_blank">
                  <span className="pi pi-download" /> Download CV
                </a>
              </>
            ),
          },
        ]
      : []),
  ];

  const title = (
    <>
      <div
        className={cn(
          "wrp-profile-img",
          Number.isInteger(activeIndex) && "img-fade",
          isFreelancer && "freelance"
        )}
      >
        <img alt="Profile image" src={talent.profile_image_url} />
        {isFreelancer && <div className="clip-overlay freelance"></div>}
      </div>
      <p>{talent.name}</p>
      <p>{talent.subhead}</p>
      <div className="wrp-company">
        <i className="icon-company" />
        <span>{talent.organisation_name || "—"}</span>
      </div>
    </>
  );

  const subTitle = (
    <>
      <div
        className={cn(
          "card-description",
          Number.isInteger(activeIndex) && "card-shrink"
        )}
      >
        <div className="card-col-12">
          <p>
            <strong>Discipline: </strong>
            {talent.discipline_name}
          </p>
        </div>
        <div className="card-col-12">
          <p>
            <strong>Role: </strong>
            {talent.specialism}
          </p>
        </div>
        <div className="card-col-12">
          <p>
            <strong>Level: </strong>
            {talent.level_name}
          </p>
        </div>
        <div className="card-col-12">
          <p>
            <strong>Clearance: </strong>
            {joinArray(talent.tro)}
          </p>
        </div>
      </div>
      <div className="card-col-12">
        <Status data={talent.status} />
      </div>
    </>
  );

  return (
    <Card
      title={title}
      subTitle={subTitle}
      header={header}
      className={cn(
        (!talent.talent_feedback ||
          talent.talent_feedback === NO_REASON_GIVEN) &&
          "without-one-tab",
        isFreelancer && "freelance"
      )}
    >
      <div className="p-m-0 card card-content">
        <Accordion
          className={cn(
            "accordion-custom",
            Number.isInteger(activeIndex) && "accordion-expanded"
          )}
          activeIndex={activeIndex}
          expandIcon={"pi pi-chevron-down"}
          collapseIcon={"pi pi-chevron-up"}
          onTabChange={(e: any) => setActiveIndex(e.index)}
        >
          {tabs.map((tab: any) => (
            <AccordionTab key={tab.id} header={tab.title}>
              <div className="wrp-description">{tab.description}</div>
            </AccordionTab>
          ))}
        </Accordion>
        {!talent.cv_url && (
          <div className="accordionItem">
            <a href={talent.portfolio_url} target="_blank">
              Portfolio
            </a>
          </div>
        )}
        {mode === "spotlight" && (
          <div className="addTalent">
            <a
              className="p-button addTalentButton"
              href={`mailto:support@meetgenie.co?subject=I would like to add ${talent.name}`}
            >
              Get in Touch
              <span className="pi pi-external-link talent-details-link" />
            </a>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Talent;
